////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-thirdColor {
  background-color: rgba(238, 238, 238, 1);
}
.bg-fourthColor {
  background-color: rgba(15, 62, 123, 1);
}
.bg-fifthColor {
  background-color: rgba(187, 110, 0, 1);
}
.bg-sixthColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-seventhColor {
  background-color: rgba(255, 255, 255, 1);
}
